/* BEGIN Thin */
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
/* END Thin */
/* BEGIN Light */
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
/* END Light */
/* BEGIN Regular */
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
/* END Regular */
/* BEGIN Medium */
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
/* END Medium */
/* BEGIN Bold */
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
/* END Bold */
/* BEGIN Black */
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
/* END Black */

/* BEGIN Icons */
/* Rules for sizing the icon. */
.material-icons.md-18,
.material-icons-outlined.md-18,
.md-button.md-icon-button.md-18 md-icon {
  font-size: 18px;
  height: 18px;
  width: 18px;
  min-height: 18px;
  min-width: 18px;
}
.material-icons.md-24,
.material-icons-outlined.md-24,
.md-button.md-icon-button.md-24 md-icon {
  font-size: 24px;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
}
.material-icons.md-36,
.material-icons-outlined.md-36,
.md-button.md-icon-button.md-36 md-icon {
  font-size: 36px;
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
}
.material-icons.md-48,
.material-icons-outlined.md-48,
.md-button.md-icon-button.md-48 md-icon {
  font-size: 48px;
  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;
}
.md-button.md-icon-button.md-18 {
  line-height: 18px;
  height: calc(18px + 2 * 8px);
  width: calc(18px + 2 * 8px);
}
.md-button.md-icon-button.md-24 {
  line-height: 24px;
  height: calc(24px + 2 * 8px);
  width: calc(24px + 2 * 8px);
}
.md-button.md-icon-button.md-36 {
  line-height: 36px;
  height: calc(36px + 2 * 8px);
  width: calc(36px + 2 * 8px);
}
.md-button.md-icon-button.md-48 {
  line-height: 48px;
  height: calc(48px + 2 * 8px);
  width: calc(48px + 2 * 8px);
}
/* Rules for using icons as black on a light background. */
.material-icons.md-dark,
.material-icons-outlined.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive,
.material-icons-outlined.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}
/* Rules for using icons as white on a dark background. */
.material-icons.md-light,
.material-icons-outlined.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive,
.material-icons-outlined.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
/* END Icons */

/* split icons */
.split-icon {
  position: relative;
  white-space: nowrap;
}
.split-icon:before {
  overflow: hidden;
  position: absolute;
  width: 50%;
}
.split-icon.lens:before {
  content: "\E3FA";
  color: #60a917;
}
