/* #region disable ngRepeat animations */
[ng-repeat].ng-animate.ng-leave {
  display: none;
}
/* #endregion */

/* #region animate ngShow & ngHide */
.animate-show,
.animate-hide,
.animate-show-hide {
  opacity: 1;
}
.animate-show.ng-hide,
.animate-hide.ng-hide,
.animate-show-hide.ng-hide {
  opacity: 0;
}
.animate-hide.ng-hide-add,
.animate-show.ng-hide-remove,
.animate-show-hide.ng-hide-add,
.animate-show-hide.ng-hide-remove {
  transition: opacity linear 1s;
}
/* #endregion */

@keyframes rotation-cw {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(1turn); }
}
@keyframes rotation-acw {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(-1turn); }
}

.spin {
  animation: rotation-cw 2s linear infinite;
}
.spin-rev {
  animation: rotation-acw 2s linear infinite;
}
.pulse {
  animation: rotation-cw 1s steps(8) infinite;
}
.pulse-rev {
  animation: rotation-acw 1s steps(8) infinite;
}

.short-tooltip {
  animation: shortTooltip ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}
@keyframes shortTooltip {
  0% {
    opacity: 0;
    transform: translate(0px, 8px);
  }
  20% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  80% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 8px);
  }
}

.pulsate {
  animation: 1.5s ease-in-out 0.5s infinite normal none running pulsate;
}
@keyframes pulsate {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}

@keyframes background-r2l {
  from {
    background-position: right;
  }
  to {
    background-position: left;
  }
}
