/* page background */
body[md-theme="callapizza"] {
  background: url('/img/customers/callapizza/background.jpg') center/cover no-repeat;
}
md-content.md-callapizza-theme {
  background-color: transparent;
}
body > md-content.md-callapizza-theme {
  background-color: rgb(255 255 255 / 90%);
}

/* app logo */
body[md-theme="callapizza"] .logo {
  content: url('/img/customers/callapizza-logo.png');
}

/* pizza spinner */
.md-callapizza-theme .spinner {
  background-color: unset;
  background-image: url('/img/customers/callapizza/cap-animation_aufessen_v4.gif');
  background-size: contain;
  border-radius: unset;
  box-shadow: unset;
}

/* content section */
.md-callapizza-theme section > h2,
body[md-theme="callapizza"] section > h2 {
  color: rgba(0, 0, 0, 0.87);
}

.md-callapizza-theme .file-unread md-icon::after {
  background-color: var(--orange);
}
