/*
===== !!! ===== CAUTION ===== !!! =====

   Write theme color specific stuff
   into `app/css/.theme-styles.css`.

===== !!! ===== CAUTION ===== !!! =====
*/

/* #region custom properties (variables) */
:root {
  --primary: #0078d7;
  --success: #60a917;
  --warning: #e40e16;
  --grey: #9e9e9e;
  --yellow: #ffed00;
  --orange: #ff9100;
}
/* #endregion */

/* #region markdown (showdown) */
[markdown-to-html] > div {
  line-height: 0;
}
[markdown-to-html] blockquote {
  position: relative;
  margin-left: 17px;
}
[markdown-to-html] blockquote::before {
  content: '';
  background-color: currentColor;
  border-radius: 2px;
  position: absolute;
  left: -16px;
  height: 100%;
  width: 4px;
}
/* #endregion */

/* #region spinner */
om-spinner .spinner {
  background-image: url('/img/spinner-200px.gif');
  background-image: url('/img/spinner-200px.svg');
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100px;
  width: 200px;
  height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  z-index: 90;
}

.spinner-warn {
  /* turn blue into red */
  filter: invert(29%) sepia(98%) saturate(7346%) hue-rotate(351deg) brightness(88%) contrast(103%);
}
/* #endregion */

/* #region payment sprite */
.payment {
  background-image: url('/img/icons/payment-sprite.png');
  background-repeat: no-repeat;
  display: block;
  width: 65px;
  height: 35px;
}
.payment-bar {
  background-position: 0px 0px;
}
.payment-creditcard {
  background-position: -84px 0px;
}
.payment-paypal {
  background-position: -168px 0px;
}
.payment-mastercard {
  background-position: -251px 0px;
}
.payment-giropay {
  background-position: -335px 0px;
}
.payment-ec {
  background-position: -418px 0px;
}
.payment-sofort {
  background-position: -501px 0px;
}
.payment-gratis {
  background-position: -584px 0px;
}
.payment-googlepay {
  background-position: -667px 0px;
}
.payment-applepay {
  background-position: -751px 0px;
}
.payment-amazonpay {
  background-position: -834px 0px;
}
.payment-cash {
  background-position: -919px 0px;
}
/* #endregion */

/* #region sprites */
.sprite-walking-men {
  background-image: url('/img/icons/walking-men-sprite.png');
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: 13px;
  display: inline-block;
  width: 13px;
  height: 23px;
}
.sprite-walking-men.sprite-orange {
  background-position: 0px -27px;
}
.sprite-walking-men.sprite-green {
  background-position: 0px -54px;
}
.sprite-walking-men.sprite-red {
  background-position: 0px -81px;
}
@media only screen and (min-resolution: 240dpi) {
  .sprite-walking-men {
    background-image: url('/img/icons/walking-men-sprite@2x.png');
  }
}
/* #endregion */

/* #region persistent notification */
.persistent-notification {
  background: white;
  box-sizing: border-box;
  padding: 1em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3em;
  width: 400px;
}
.persistent-notification .actions .md-button {
  float: right;
  margin-right: 0;
  margin-bottom: 0;
}
/* #endregion */

/* #region badge */
.badge, .badge-light {
  border-radius: 100px;
  color: white;
  display: inline-block;
  font-family: system-ui;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  padding: .35em .65em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

utm-badge {
  border-radius: 100px;
  display: inline-block;
  padding-block-start: 3px;
  padding-block-end: 2px;
  padding-inline: 12px;
  margin-block: -4px;
}
utm-badge > span:first-child {
  display: inline-block;
  vertical-align: middle;
  margin-inline-end: 4px;
}
/* #endregion */

/* #region accordion */
om-accordion .accordion-part-title {
  cursor: pointer;
  outline: none;
}
om-accordion .accordion-part-title .triangle {
  display: inline-block;
  transition: transform 500ms ease;
  width: 16px;
}
om-accordion .accordion-part-title .triangle.expand {
  transform: rotate(90deg);
}
om-accordion .accordion-part-content {
  margin-left: calc(16px + .5em);
}
om-accordion .accordion-part-content > :first-child {
  display: block;
  padding-top: .5em;
}
om-accordion .accordion-part-content.collapse {
  overflow: hidden;
  position: relative;
  transition: height 500ms ease;
}
/* #endregion */

/* #region select */
.md-select-menu-container.show-8-options md-select-menu,
.md-select-menu-container.show-8-options md-select-menu md-content {
  max-height: 400px;
}
.md-select-menu-container.slim md-select-menu md-content {
  min-width: unset;
}
.omSelect .select-header {
  box-shadow:
    0 1px 0 0 rgba(0, 0, 0, 0.1),
    0 0   0 0 rgba(0, 0, 0, 0.14),
    0 0   0 0 rgba(0, 0, 0, 0.12);
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}
.omSelect .select-header-searchbox {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0 10.66667px;
}
.omSelect md-optgroup md-checkbox {
  display: block;
  margin: 0;
  padding: 14px 16px 14px 10.66667px;
}
.omSelect md-optgroup md-checkbox .md-container {
  left: 10.66667px;
}
.omSelect md-optgroup md-checkbox:focus,
.omSelect md-optgroup md-checkbox:hover {
  background-color: rgba(117, 134, 138, 0.18);
}
/* #endregion */

/* #region pagination */
om-pagination {
  display: block;
  margin: 8px 0 0 0;
}
om-pagination md-input-container {
  margin: -4px 0 0 0;
}
om-pagination .md-button.md-icon-button {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  min-height: 24px;
}
/* #endregion */

/* #region omStore */
om-store {
  display: inline-block;
}
/* #endregion */

/* #region toolbar */
om-toolbar {
  padding: 1em calc(1.5em + 12px);
  z-index: 1;
}
om-toolbar div {
  display: inline-block;
}
om-toolbar .md-button {
  font-weight: bold;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  min-width: 44px;
  min-height: 0;
  line-height: 24px;
}
om-toolbar .icons {
  border-right: 1px solid;
  padding-left: 8px;
  padding-right: 8px;
}
/* #endregion */

/* #region ordertable */
om-ordertable:not(:last-child) {
  margin-bottom: 1.5em;
}
om-ordertable tr.group-orders > td:first-child {
  border-left: 2px solid !important;
  padding-left: 2px !important;
}
om-ordertable tr.group-orders > td:last-child {
  border-right: 2px solid !important;
  padding-right: 2px !important;
}
om-ordertable tr.group-orders-first > td {
  border-top: 2px solid !important;
  padding-top: 2px !important;
}
om-ordertable tr.group-orders-last > td {
  border-bottom: 2px solid !important;
  padding-bottom: 2px !important;
}
/* #endregion */

/* #region OTS status / SC status */
.otsstatus,
.scstatus {
  cursor: default;
  display: inline-block;
  font-weight: bold;
  position: relative;
  text-align: center;
  width: 158px;
  height: 50px;
}
.status-name,
.status-info,
.status-reload {
  line-height: 24px;
}
.status-loader,
.status-cancel,
.status-inactive {
  cursor: default;
  line-height: 50px;
}
.status-info,
.status-reload {
  float: left;
}
.status-info:focus,
.status-reload:focus {
  outline: none;
}
.otsstatus .status-info,
.otsstatus .status-reload {
  width: 30px;
}
.scstatus .status-info,
.scstatus .status-reload {
  width: 38px;
}
.scstatus .status-info-wide {
  width: 78px;
}
.status-name {
  margin-bottom: 2px;
}
.status-info {
  margin-right: 2px;
}
.status-reload,
.scstatus-active {
  cursor: pointer;
}
.status-reload:hover,
.scstatus-active:hover {
  background: black !important;
}
.scstatus .status-info:nth-last-child(2) {
  margin-right: 0;
}
.status-loader span.rotate {
  animation: rotation-cw 2s infinite linear;
  display: inline-block;
  line-height: normal;
}
.scstatus .status-info md-icon {
  font-size: 20px;
  line-height: 1px;
  min-height: 0px;
  height: 1px;
}
.scstatus .status-reload {
  position: absolute;
  top: 26px;
  width: 100%;
}

.fraud-decisions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;
}
.fraud-decisions > * {
  border: 1px solid;
  border-radius: .5rem;
  padding-block: 1rem 2rem;
  text-align: center;
}
.fraud-decisions .md-button {
  margin-block: 1.5rem;
}
/* #endregion */

/* #region OTS detail */
om-otsdetail {
  display: block;
}
om-otsdetail .md-title {
  position: relative;
}
om-otsdetail .md-title button {
  margin-top: -10px;
  margin-bottom: -10px;
}
om-otsdetail .detail-highlight,
om-otsdetail .detail-highlight .detail-more-btn md-icon {
  color: white;
}
om-otsdetail .datatable td {
  vertical-align: top;
}
om-otsdetail .datatable tbody .detail-highlight-success,
om-otsdetail .datatable tbody tr.detail-highlight-success .subtable tbody tr {
  background-color: var(--success) !important;
}
om-otsdetail .datatable tbody .detail-highlight-warning,
om-otsdetail .datatable tbody tr.detail-highlight-warning .subtable tbody tr {
  background-color: var(--warning) !important;
}

/* om-otsdetail detail-more */
om-otsdetail .detail-more .datatable {
  margin-top: -2px;
}
om-otsdetail .detail-more-btn {
  cursor: pointer;
}
om-otsdetail .detail-more-btn md-icon {
  float: right;
}
om-otsdetail .detail-more {
  overflow-y: auto;
  max-height: 100px;
  transition: max-height .25s ease-in;
}
om-otsdetail .detail-more.ng-hide {
  max-height: 0;
  transition: max-height .15s ease-out;
}
om-otsdetail .detail-more.ng-hide-add,
om-otsdetail .detail-more.ng-hide-remove {
  overflow: hidden;
}
/* #endregion */

/* #region tag-object (icon, label) */
.tag-object {
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
}
om-orderannotationicons .tag-object {
  padding: 0;
  margin: 2px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  overflow: hidden;
}
.icon,
.label,
.label md-icon {
  color: white;
}
.label {
  min-width: 150px;
  text-align: center;
  text-transform: uppercase;
}
.label img {
  margin-right: 8px;
}

.icon.icon-addresscomment,
.label.label-addresscomment {
  background-color: #60a917;
}
.icon.icon-cancellation,
.label.label-cancellation {
  background-color: #e40e16;
}
.icon.icon-extras,
.label.label-extras,
.label.label-itemcomment {
  background-color: #ff9100;
}
.icon.icon-ec,
.label.label-ec {
  background-color: unset;
}
.icon.icon-onlinepayment,
.label.label-onlinepayment {
  background-color: #0078d7;
}
.icon.icon-preorder,
.label.label-preorder,
.icon.icon-termin,
.label.label-termin {
  background-color: #00b7c3;
}
.icon.icon-takeaway,
.label.label-takeaway {
  background-color: #f7630c;
}
.icon.icon-testorder,
.label.label-testorder {
  background-color: #75868a;
}
.icon.icon-contactless,
.label.label-contactless {
  background-color: black;
}
.icon.icon-contactless img {
  margin: 4px 2px 5px;
}

.label.label-cancellation,
.label.label-testorder {
  margin-left: 0.5em;
}
.label.label-storeident {
  line-height: normal;
  min-width: 0;
}
.label.label-extras,
.label.label-itemcomment {
  text-align: left;
  text-transform: none;
  white-space: pre-line;
}
.label.label-itemcomment {
  margin: 4px 1px;
}
.label.label-extras,
.label.label-preorder,
.label.label-termin,
.label.label-contactless {
  padding: 4px 8px;
  overflow: auto;
}
/* #endregion */

/* #region google charts */
[google-chart] svg > g:last-child > g:last-child { /* fix tooltip flicker */
  pointer-events: none;
}
/* #endregion */

/* #region progress-wrapper */
.progress-wrapper {
  position: relative;
  margin: 0 8px;
}
.round-progress-wrapper {
  width: 32px;
  height: 32px;
}
.progress-wrapper .progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
/* #endregion */

/* #region bootstrap progress */
.bs-progress {
  border-radius: .25rem;
  display: flex;
  font-size: .75rem;
  height: 1rem;
  overflow: hidden;
}
.bs-progress-bar {
  color: white;
  display: block;
  line-height: 1rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  transition: width 500ms ease;
}
.bs-progress-done {
  background-color: var(--success);
}
.bs-progress-fail {
  background-color: var(--warning);
}
/* #endregion */

/* #region ranking */
.ranking-box-wrapper {
  padding: 10px;
}
.ranking-box {
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);
  border: 1px solid #ffffff;
  height: 100%;
  padding: 20px;
  position: relative;
}
.ranking-box-wrapper:nth-child(-n+3) .ranking-box {
  text-align: center;
}
.ranking-box-wrapper:nth-child(-n+3) .ranking-box > div > div:not(.ranking-place) {
  margin: 20px auto;
}
.ranking-box-wrapper:nth-child(1) .ranking-box {
  background: #fff7cc; /* Old browsers */
  background: -moz-linear-gradient(135deg, #fff7cc 0%, #ffffff 35%, #ffffff 65%, #fff7cc 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(135deg, #fff7cc 0%, #ffffff 35%, #ffffff 65%, #fff7cc 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #fff7cc 0%, #ffffff 35%, #ffffff 65%, #fff7cc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff7cc', endColorstr='#fff7cc', GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.ranking-box-wrapper:nth-child(2) .ranking-box {
  background: #ebebeb; /* Old browsers */
  background: -moz-linear-gradient(135deg, #ebebeb 0%, #ffffff 35%, #ffffff 65%, #ebebeb 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(135deg, #ebebeb 0%, #ffffff 35%, #ffffff 65%, #ebebeb 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ebebeb 0%, #ffffff 35%, #ffffff 65%, #ebebeb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebebeb', endColorstr='#ebebeb', GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.ranking-box-wrapper:nth-child(3) .ranking-box {
  background: #ead5c1; /* Old browsers */
  background: -moz-linear-gradient(135deg, #ead5c1 0%, #ffffff 35%, #ffffff 65%, #ead5c1 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(135deg, #ead5c1 0%, #ffffff 35%, #ffffff 65%, #ead5c1 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ead5c1 0%, #ffffff 35%, #ffffff 65%, #ead5c1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ead5c1', endColorstr='#ead5c1', GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.ranking-medal img {
  display: none;
}
.ranking-box-wrapper:nth-child(1) .ranking-medal img:nth-child(1),
.ranking-box-wrapper:nth-child(2) .ranking-medal img:nth-child(2),
.ranking-box-wrapper:nth-child(3) .ranking-medal img:nth-child(3) {
  display: inline-block;
}
.ranking-medal-sm {
  padding: 4px 0 !important;
}
.ranking-medal-sm img {
  height: 56px;
}
.ranking-place {
  font-size: 32px;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
}
.section-box .ranking-place {
  padding: 8px 0 !important;
}
.ranking-box-wrapper:nth-child(1) .ranking-place {
  color: #ffd700;
}
.ranking-box-wrapper:nth-child(2) .ranking-place {
  color: #c0c0c0;
}
.ranking-box-wrapper:nth-child(3) .ranking-place {
  color: #b87333;
}
.ranking-box-wrapper:nth-child(n+4) .ranking-place {
  line-height: 100px;
  margin-right: .5em;
  min-width: 100px;
  text-align: center;
}
.ranking-box-wrapper:nth-child(4) .ranking-place,
.ranking-box-wrapper:nth-child(5) .ranking-place {
  background-image: url('/img/ranking/wreath.png');
  background-position: center;
  background-repeat: no-repeat;
}
.ranking-place-text {
  display: none;
}
.ranking-box-wrapper:nth-child(-n+3) .ranking-place-text {
  display: inline;
}
.ranking-store {
  font-weight: bold;
  font-style: italic;
}
.ranking-box-wrapper:nth-child(-n+3) .ranking-store {
  font-size: 24px;
}
.ranking-store .label-storeident {
  padding: 4px 8px;
}
.ranking-box-wrapper:nth-child(1) .label-storeident {
  background-color: #ffd700;
}
.ranking-box-wrapper:nth-child(2) .label-storeident {
  background-color: #c0c0c0;
}
.ranking-box-wrapper:nth-child(3) .label-storeident {
  background-color: #b87333;
}
.ranking-box-wrapper:nth-child(-n+3) .ranking-data table {
  width: 100%;
}
.ranking-data table td:first-child {
  padding-right: 2em;
}
.ranking-data .major {
  font-weight: bold;
}
.ranking-data .minor {
  font-style: italic;
}
.ranking-chart {
  height: 200px;
  position: relative;
}
.ranking-chart.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  z-index: 90;
}
.ranking-chart-fullscreen-info {
  display: none;
}
.ranking-chart.fullscreen .ranking-chart-fullscreen-info {
  display: block;
  text-align: center;
}
.ranking-chart-fullscreen-info .ranking-store {
  margin: 40px 0 20px;
}
.ranking-chart .fullscreen-off {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ranking-chart .fullscreen-on {
  display: none;
  margin: 0;
  position: absolute;
  right: 0;
}
.ranking-chart:not(.fullscreen):hover .fullscreen-on {
  display: inline-block;
}
/* #endregion */

/* #region CRM */
.print-labels {
  border-collapse: collapse;
  background-color: white;
}
.print-labels td {
  border: 1px dashed lightgray;
  padding: 0;
  position: relative;
  vertical-align: top;
}
.print-labels td:hover {
  border: 1px solid gray;
}
.print-labels td md-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.print-labels td md-icon::before {
  content: '';
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 50%;
  z-index: -1;
}
.label-placeholder {
  box-sizing: border-box;
  padding: 6px;
  width: 100%;
}
.label-placeholder > div {
  background-color: #eeeeee;
  border-radius: 4px;
  height: 8px;
  margin-bottom: 4px;
}
.print-labels td.warning .label-placeholder {
  display: none;
}

/* Campaign */
.campaign-image-preview {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: .5em;
  padding: 4px;
  max-width: 900px;
}
/* #endregion */

/* #region HRM */
.hrm-grid .md-button {
  padding-top: 14px;
}
.hrm-staff md-input-container, .hrm-staff md-select,
.hrm-shift md-input-container, .hrm-shift md-select,
.hrm-costs md-input-container {
  margin: 0;
}
.hrm-shift md-select {
  text-align: initial;
}
.hrm-shift-header {
  font-weight: bold;
  text-align: center;
  transform: rotate(180deg);
  min-width: 22px;
  writing-mode: vertical-rl;
}
.hrm-shift-key {
  border: 1px solid;
  display: inline-block;
  padding: 4px;
  text-align: center;
  width: 50px;
}
.datatable .hrm-shift-chkbox {
  padding: 16px;
}

/* mimic mdSelect (more in theme-styles.css) */
.hrm-shift select {
  background-color: transparent;
  border-width: 0 0 1px 0;
  color: currentColor;
  cursor: pointer;
  outline: none;
}
.hrm-shift optgroup {
  text-transform: uppercase;
}
.hrm-shift option {
  text-transform: none;
}
/* #endregion */

/* #region openemm */
.openemm table md-input-container {
  margin-top: 0;
  margin-bottom: 0;
}
/* #endregion */

/* #region transmissions */
.transmissions .datatable tr.new td:last-child button {
  overflow: visible;
  position: relative;
}
.transmissions .datatable tr.new td:last-child button::after {
  box-shadow:
    0px 1px 8px  0px rgba(0, 0, 0, 0.2),
    0px 3px 4px  0px rgba(0, 0, 0, 0.14),
    0px 3px 3px -2px rgba(0, 0, 0, 0.12);
  content: '!';
  color: white;
  background-color: var(--warning);
  border: 2px solid white;
  border-radius: 10px;
  box-sizing: border-box;
  line-height: normal;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
}
.transmissions-timer-goal {
  color: var(--grey);
  display: none;
}
.transmissions .md-icon-after {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  background: white;
  border-radius: 12px;
  position: absolute;
  transform: translate(-16px, -2px);
}
/* #endregion */

/* #region OTS failure */
tr.ots_failure td {
  border-top: 2px solid var(--warning);
  border-bottom: 2px solid var(--warning);
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
tr.ots_failure td:first-child {
  border-left: 2px solid var(--warning);
  padding-left: 2px !important;
}
tr.ots_failure td:last-child {
  border-right: 2px solid var(--warning);
  padding-right: 2px !important;
}
tr.ots_failure::after {
  background-color: var(--warning);
  color: white;
  content: 'Fehler!';
  display: block;
  font-weight: bold;
  left: 120px;
  margin-top: .5em;
  padding: 1em;
  position: absolute;
}
tr.ots_failure.transmission_failure::after {
  content: 'Vermittlungsfehler: Besteller anrufen!';
}
/* #endregion */

/* #region storesettings */
.deliverytimes table,
.openinghours table,
.preorderlimit table {
  position: relative;
  width: 100%;
}

.deliverytimes th,
.openinghours th,
.preorderlimit th {
  text-transform: uppercase;
  padding-top: 5px;
  padding-bottom: 5px;
}

.deliverytimes-slider td:first-child,
.deliverytimes-slider td:last-child {
  width: 100px;
}
.deliverytimes md-slider {
  margin-block: 14px;
}
.deliverytimes .acom-info {
  position: absolute;
  bottom: 61px;
  right: 2px;
  pointer-events: none;
  transform: translateZ(0);
}

.openinghours .float-right-info {
  position: absolute;
  top: 7px;
  right: 3px;
  padding: inherit;
  z-index: 1;
}
/* #endregion */

/* #region preorderlimit */
.preorderlimit .table-preview {
  margin: 1em 0 2em;
}
.preorderlimit .table-preview td {
  width: 150px;
}
.preorderlimit .table-preview td:last-child {
  width: 50px;
}
.preorderlimit .table-preview h4 {
  margin-bottom: 4px;
}
.preorderlimit-dialog h4 {
  margin: 2em 0 1em 0;
}
.preorderlimit-dialog h4:first-child {
  margin-top: 0;
}
.preorderlimit-dialog .table-weekday > tbody > tr > td:first-child {
  min-width: 100px;
  padding-top: 12px;
}
.preorderlimit-dialog .table-custom > tbody > tr > td:first-child {
  padding-top: 5px;
  width: 200px;
}
.preorderlimit-dialog > table > tbody > tr > td:nth-child(2) {
  min-width: 100px;
  padding-top: 7px;
}
.preorderlimit-dialog .table-custom > tbody > tr > td:last-child {
  padding-top: 4px;
}
.preorderlimit-dialog .table-inner td:nth-child(2) {
  padding-top: 9px;
}
.preorderlimit-dialog .table-inner td:nth-child(4) {
  padding-left: 26px;
}
.preorderlimit-dialog .table-inner td:nth-last-child(2) {
  min-width: 40px;
}
.preorderlimit-dialog .table-inner td:nth-last-child(1) {
  min-width: 200px;
}
.preorderlimit-dialog md-switch,
.preorderlimit-dialog .input,
.preorderlimit-dialog .button-add-interval,
.preorderlimit-dialog .button-del-custom {
  margin-top: 0;
  margin-bottom: 0;
}
.preorderlimit-dialog .button-del-interval {
  margin: -3px 0;
}
.preorderlimit-dialog .input-time,
.preorderlimit-dialog .input-limit {
  width: 100px;
}
.preorderlimit-dialog .button-del-interval md-icon {
  color: inherit;
}
/* #endregion */

/* #region pinboard */
.pinboard-form,
.pinboard-entry {
  padding: 0 !important;
}
.pinboard-form > div,
.pinboard-entry > div {
  padding: .75em;
  margin: 0;
}
/* #endregion */

/* #region files */
.files-explorer {
  padding-left: 0;
}
.files-explorer li {
  line-height: 40px;
  list-style-type: none;
  padding-inline: 12px;
}
.files-breadcrumbs {
  margin-block-start: 4px;
}
.files-breadcrumbs md-icon,
.files-explorer md-icon {
  margin-inline-end: 8px;
  padding-block-end: 4px;
}
.files-breadcrumbs md-icon.chevron {
  margin: 0 8px;
  padding: 0;
  float: left;
}
.file-unread md-icon {
  position: relative;
}
.file-unread md-icon::after {
  --file-unread-badge-size: 12px;

  content: '';

  position: absolute;
  top: calc(-1 * var(--file-unread-badge-size) / 4);
  right: calc(-1 * var(--file-unread-badge-size) / 4);

  width: var(--file-unread-badge-size);
  height: var(--file-unread-badge-size);
  border: 1px solid white;
  border-radius: var(--file-unread-badge-size);

  background-color: var(--warning);
}
/* #endregion */

/* #region google maps */
.gm-style .infobox {
  background: none padding-box white;
  border: 0px;
  border-radius: 2px;
  margin: 10px;
  padding: 17px;
  position: relative;
  overflow: hidden;
  color: black;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
.gm-style hr {
  background-color: rgb(230, 230, 230);
  margin: 6px 0px;
}
md-dialog .gm-style {
  color: black;
  font-size: 16px;
}
/* #endregion */

/* #region tutorials */
.tutorials-admin iframe,
.tutorials-wrapper .video-frames iframe {
  aspect-ratio: 16 / 9;
  width: 320px;
  height: auto;
  display: block;
}
.tutorials-wrapper {
  position: absolute;
  bottom: 104px;
  right: 26px;
}
.tutorials-wrapper .video-frames {
  gap: 1rem;
  margin-block-end: 1rem;
}
.tutorials-wrapper .md-button {
  --button-size: 56px;
  --button-icon-size: 40px;

  background-color: #3ab2e6 !important;
  width: var(--button-size);
  height: var(--button-size);
  line-height: var(--button-icon-size);
}
.tutorials-wrapper .md-button md-icon {
  color: white !important;
  width: var(--button-icon-size);
  height: var(--button-icon-size);
  font-size: var(--button-icon-size);
}
.tutorials-wrapper .badge {
  background-color: darkorange;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: .1em .2em;
  border-radius: 4px;
  pointer-events: none;
}
/* #endregion */

/* #region stepper */
.stepper {
  --stepper-size: 1.75rem;
  --stepper-border-size: 2px;
  --stepper-font-size: 14px;
  --stepper-line-size: 2px;

  display: flex;
  flex-wrap: wrap;
}
.stepper-item {
  display: flex;
  flex: 1;
  flex-direction: column;

  position: relative;

  font-size: var(--stepper-font-size);
  text-align: center;
}
.stepper-counter {
  z-index: 1;

  box-sizing: border-box;
  width: var(--stepper-size);
  height: var(--stepper-size);
  border: var(--stepper-border-size) solid;
  border-radius: 50%;
  margin-inline: auto;

  font-weight: bold;
  line-height: calc(var(--stepper-size) - 2 * var(--stepper-border-size));
}
.stepper-title {
  margin-block-start: .5rem;
  margin-inline: auto;

  text-transform: uppercase;
}
.stepper-item:not(:first-child)::before {
  content: '';
  position: absolute;
  top: calc((var(--stepper-size) - var(--stepper-line-size)) / 2);
  left: -50%;
  order: -1;

  width: 100%;
  height: var(--stepper-line-size);
}
.stepper-item.complete,
.stepper-item.active {
  --stepper-border-size: 4px;
  --stepper-line-size: 4px;
}
/* #endregion */
